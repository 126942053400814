<template>
  <div class="appointmentList-view">
    <div class="flex-a-c mb10">
      <span class="fs8 mr10 cA3">公司名称</span>
      <span class="fs8 cA1">{{companyName}}</span>
    </div>
    <div class="flex-a-b-c">
      <div class="flex-a-c">
        <el-input
              type="text"
              placeholder="请输入部门名称/管理员姓名"
              v-model="departmentKey"
              style="width: 240px"
              show-word-limit>
        </el-input>
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="getTableData"
          >搜索</el-button
        >
        <el-button
          class="v-primary ml10"
          type="primary"
          @click="addDepartment"
          >新增部门</el-button
        >
      </div>
      <el-button
          v-if="qywxSwitch"
          class="v-primary ml10"
          type="primary"
          @click="enterpriseLicense = true"
          >导入企微组织架构</el-button
        >
    </div>

    <commonTable
      :tableData="tableData"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :loading="loading"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="name" width="100" align="center" label="部门名称" />
        <el-table-column prop="Administrator" width="100" align="center" label="部门负责人">
          <template slot-scope="scope">
            <div class="flex-c-c flex-wrap-y">
              <div v-for="(item,index) in scope.row.adminEmployees" :key="index">
                <div class="hover_pointer mr10">
                  {{item.employeeName}}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="num" align="center" width="100"  label="部门人数" />
        <el-table-column prop="state" align="center" label="已授权成员">
          <template slot-scope="scope">
            <div class="flex-c-c flex-wrap-y" v-if="scope.row.employeeList">
              <div v-for="(item,index) in scope.row.employeeList" :key="index">
                <div v-if="index < 6" class="member-box hover_pointer">
                  {{item.employeeName}}
                </div>
              </div>
              <div v-if="scope.row.employeeList.length > 6" class="more-box hover_pointer" @click="checkAll(scope.row, true)">
                查看所有
              </div>
            </div>
            <div v-else></div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="num" align="center" width="120"  label="待授权成员数">
          <template slot-scope="scope">
            <div class="flex-c-c flex-wrap-y">
              <div>
                {{scope.row.num}}
              </div>
              <span v-if="scope.row.num > 0" style="color: $color-1; cursor: pointer;margin-left:10px" @click="checkAll(scope.row, false)">
              查看
              </span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="state" width="110" align="center" label="操作">
          <template slot-scope="scope"
            ><span
              style="color: $color-1; cursor: pointer;margin-right:10px"
              @click="editDepartment(scope.row)"
            >
              编辑
            </span>
            <span
              style="color: #FD634E; cursor: pointer;margin-right:10px"
              @click="dissolve(scope.row)"
            >
              解散
            </span>

          </template>
        </el-table-column>
      </template>
    </commonTable>
    <!-- 查看所有成员 -->
    <el-dialog
          class="label_dialog"
          :title= checkTitle
          width="30%"
          center
          :visible.sync="checkVisible"
          :modal-append-to-body="false"
          @closed="checkVisible = false"
        >
        <div class="" v-if="memberData.employeeList">
          <div class="mb20">
            <span class="fs8 cA2 ml10">共 {{checkAuth ? memberData.num2 : memberData.num}} 位成员</span>
          </div>
          <div class="d-flex flex-wrap-y member-scroll">
            <div v-for="(item,index) in memberData.employeeList" :key="index">
              <div class="member-box pb20" v-if="checkAuth ? item.openUserid : !item.openUserid">{{item.employeeName}} - {{item.phone}}</div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt20">
            <el-button
              class="v-primary ml10 "
              @click="checkVisible = false"
              type="primary"
              >确认</el-button
            >
          </div>

        </div>

    </el-dialog>
    <!-- 部门成员列表 -->
    <el-dialog
          class="label_dialog"
          title="部门成员列表"
          width="630px"
          center
          :visible.sync="editVisible"
          :modal-append-to-body="false"
          @closed="editVisible = false"
        >
        <div class="w100p flex-a-c mb50">
          <p class="mr40">部门名称</p>
          <el-input
            type="text"
            placeholder="请输入部门名称"
            v-model="currentDepartment.name"
            style="width: 240px"
            show-word-limit>
          </el-input>
        </div>
        <div class="w100p flex-a-c mb50">
          <p class="mr18">部门负责人</p>
          <el-button
            class="v-primary ml10"
            type="primary"
            @click="administratorVisible = true"
            >{{checkedArr.length > 0 ? '修改' : '新增'}}</el-button
          >
          <span class="size-12 ml-16">*提示：管理权限为‘管理员’的成员才可设置为‘部门负责人’</span>
        </div>
        <div class="w100p flex-a-c mb30" v-if="checkedArr">
          <el-checkbox  class="mr30"
                        style="margin-left: 0"
                        v-for="(item, index) in checkedArr"
                        :key="index"
                        :checked="true"
                        :disabled="true"
                        border
                        >{{ item.employeeName }}</el-checkbox>


        </div>
        <div class="w100p flex-a-c mb50">
          <p class="mr5">部门成员列表</p>
          <div  class="flex-a-c">
            <el-button
              class="v-primary ml10"
              type="primary"
              @click="selectedAll"
              >全选</el-button>
            <el-button
              class="v-primary ml10"
              type="primary"
              @click="memberVisible = true"
              >新增</el-button>
            <el-button
              class="v-primary ml10"
              type="danger"
              plain
              @click="remove_member"
              >移除</el-button>
          </div>
        </div>
        <div class="flex-a-c flex-wrap-y mb30">
          <el-checkbox-group  v-model="checkedMember">
          <el-checkbox  class="mr30 mb30"
                        style="margin-left: 0"
                        v-for="(item, index) in checkedMemberArr"
                        :key="index"
                        @change="checkbox_tap"
                        :label="item.empId"
                        border
                        >{{ item.employeeName }}</el-checkbox>
          </el-checkbox-group>
        </div>

        <el-button
          class="v-primary ml10 "
          style="margin-left:254px !important"
          type="primary"
          @click="saveDepartment"
          >保存</el-button
        >
    </el-dialog>
    <!-- 管理员列表 -->
    <el-dialog
          class="label_dialog"
          title="管理员列表"
          width="20%"
          center
          :visible.sync="administratorVisible"
          :modal-append-to-body="false"
          @closed="checkVisible = false"
        >
        <div class="">
          <div class="mb20 d-flex">
            <el-input
              type="text"
              placeholder="请输入管理员姓名"
              v-model="administratorKey"
              style="width: 240px"
              show-word-limit>
            </el-input>
            <el-button
              class="v-primary ml10"
              type="primary"
              @click="getAdministrator"
              >搜索</el-button
            >
          </div>
          <div class="d-flex flex-wrap-y member-scroll">
            <div class="d-flex flex-column">
              <el-checkbox-group  v-model="checkedAdministrator" :max="5" class="d-flex flex-column">
                <el-checkbox
                  class="mr30 mb30"
                  style="margin-left: 0"
                  v-for="(item, index) in administratorList"
                  :key="index"
                  :checked="item.isSelect"
                  @change="checkbox_tap"
                  :label="item.empId"
                  >{{ item.employeeName }}</el-checkbox>
                </el-checkbox-group>
            </div>

          </div>
          <div class="d-flex justify-content-center align-items-center">
            <p v-if="administratorList.length == 0 ">暂无管理员</p>
            <el-button
              class="v-primary ml10"
              v-if="administratorList.length > 0 "
              @click="administratoronConfirm"
              type="primary"
              >确认</el-button
            >
            <el-button
              class="v-primary ml10"
              v-else
              @click="toAdministratoronList"
              type="primary"
              >去设置</el-button
            >
          </div>
        </div>
    </el-dialog>
    <!-- 新增成员列表 -->
    <el-dialog
          class="label_dialog"
          title="成员列表"
          width="20%"
          center
          :visible.sync="memberVisible"
          :modal-append-to-body="false"
          @closed="checkVisible = false"
        >
        <div class="">
          <div class="mb20 d-flex">
            <el-input
              type="text"
              placeholder="请输入成员姓名"
              v-model="memberKey"
              style="width: 240px"
              show-word-limit>
            </el-input>
            <el-button
              class="v-primary ml10"
              type="primary"
              @click="getCompanyList"
              >搜索</el-button
            >

          </div>
          <div class="d-flex flex-wrap-y member-scroll">
            <div class="d-flex flex-column">
              <el-checkbox-group  v-model="addMember"  class="d-flex flex-column">
                <el-checkbox
                  class="mr30 mb30"
                  style="margin-left: 0"
                  v-for="(item, index) in staffList"
                  :key="index"
                  :label="item.empId"
                  @change="checkbox_tap"
                  >{{ item.employeeName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <el-button
              class="v-primary ml10"
              @click="memberConfirm"
              type="primary"
              >确认</el-button
            >
          </div>
        </div>
    </el-dialog>
    <!-- 企业微信组织架构 -->
    <el-dialog
          class="label_dialog"
          title="导入企业微信组织架构"
          width="30%"
          center
          :visible.sync="enterpriseLicense"
          :modal-append-to-body="false"
          @closed="checkVisible = false"
        >
        <!-- <div class="flex-a-c mb20">
          企业微信  app_id
          <el-tooltip
            class="item ml10 mr10"
            effect="light"
            placement="bottom"
          >
            <div slot="content">
              <p class="cA1 fs7 fwbold mb10">APP-ID问号：</p>
              <p class="cA1 fs6 mb10">此内容需在企业微信后台进行查看。</p>
              <p class="cA1 fs6 mb10">请打开https://work.weixin.qq.com/wework_admin/login使用企业微信扫码登录后，可在“我的企业”中“企业信息”里找到“企业ID”</p>
              <a href="https://wt-box.worktile.com/public/a051c7e8-6036-4735-b7d9-0dcc288a416b" target="_blank">
                <img src="https://wt-box.worktile.com/public/a051c7e8-6036-4735-b7d9-0dcc288a416b" style="width:500px"> 
              </a>
            </div>
            <i style="font-size: 16px" class="el-icon-question" />
          </el-tooltip>
          <el-input
            type="text"
            v-model="app_id"
            style="width: 240px"
            show-word-limit>
          </el-input>
        </div>
        <div class="flex-a-c mb20">
          企业微信  secert
          <el-tooltip
            class="item ml10 mr10"
            effect="light"
            placement="bottom"
          >
            <div slot="content">
              <p class="cA1 fs7 fwbold mb10">secert问号：</p>
              <p class="cA1 fs6 mb10">此内容需在企业微信后台进行查看。</p>
              <p class="cA1 fs6 mb10">请打开https://work.weixin.qq.com/wework_admin/login使用企业微信扫码登录后，可在“管理工具”中“通讯录同步”里找到“secert”</p>
              <div class="">
                <a href="https://wt-box.worktile.com/public/c13ea983-278b-40d1-9647-ae34b086aa94" target="_blank">
                  <img src="https://wt-box.worktile.com/public/c13ea983-278b-40d1-9647-ae34b086aa94" style="width:500px;"> 
                </a>
              </div>
              <a href="https://wt-box.worktile.com/public/4ec9c329-4b63-4909-bdab-658888d8960b" target="_blank">
                <img src="https://wt-box.worktile.com/public/4ec9c329-4b63-4909-bdab-658888d8960b" style="width:500px;"> 
              </a>
            </div>
            <i style="font-size: 16px" class="el-icon-question" />
          </el-tooltip>
          <el-input
            type="text"
            v-model="secert"
            style="width: 240px;"
            show-word-limit>
          </el-input>
        </div> -->
        <div v-if="!isQywxAuth" class="flex-a-c mb20">
          <p class="cA1 fs7 fwbold mb20"><!--请填写上面两个企业微信内容进行授权导入。<br/>-->未填写企微信息的账号并授权好，系统将无法导入。</p>
          <el-button
            class="v-primary ml10"
            @click="toAppletConfig"
            type="primary"
            >去填写</el-button>
          <el-button
            class="v-primary ml10"
            @click="toEnterpriseMember"
            type="primary"
            >去授权</el-button>
        </div>
        <p class="cred fs7 fwbold mb20">导入企业微信组织架构后，原组织架构将会被替代，<br/>所有原部门数据将会被清除，请慎重操作。</p>
        <div class="flex-c-c">
          <el-button
              class="v-primary ml10"
              @click="enterpriseLicense = false"
              >取消</el-button
            >
            <el-button
              class="v-primary ml10"
              @click="importConfirm"
              type="primary"
              >确认</el-button
            >
        </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  queryDepartment,
  queryPageEmployee,
  addDepartment,
  queryPageDepartment,
  queryPageManager,
  queryNotDepart,
  updateDepartment,
  disbandDepartment
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
import { mapState } from 'vuex';
import { syncDepartment, qywxSetting } from "@/api/qywx.js";

export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      optionList: "", //全部分类
      tableData: [],
      staffList:[],
      total: 0, //总条数
      currentPage: 1, //当前页数
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      checkVisible:false,//新增部门开关
      editVisible: false, //编辑部门
      memberData:'',//成员数据
      administratorVisible:false, //编辑管理员开关
      memberVisible:false, //新增成员开关
      currentDepartment:'',//当前部门信息
      checkedAdministrator:[], //选择的管理员
      checkedMember:[], // 选择成员
      addMember:[], //新增成员
      checkedArr:'',//当前选择的管理员
      checkedMemberArr:'', // 当前选择的成员列表
      adminEmployees:'' ,//已选择的管理员
      administratorList:[], //成员列表
      departmentId:'',//部门id
      departmentKey:'',// 部门查询key
      administratorKey:'', // 管理员查询key
      memberKey:'',// 成员查询key
      jurisdictionA:'',//查询权限
      jurisdictionB:'',//查询权限
      jurisdiction:'',//查询权限
      companyName:'', // 公司名字
      enterpriseLicense:false, //企业授权
      app_id:'', //企业appId
      secert:'', //秘钥
      checkAuth: false, //是否授权
      checkTitle: '', //弹窗title
      qywxSwitch: 0,
      isQywxAuth: 0
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      this.jurisdictionA = val.some(item => ['02100',].includes(item))//部门管理(自己)
      this.jurisdictionB = val.some(item => ['02101',].includes(item))//部门管理(所有)
      if(this.jurisdiction){
        this.getTableData()
      }else{
        this.getTableData()
      }
      if(this.jurisdictionA){
        this.jurisdiction = false
        if(this.jurisdictionB){
          this.jurisdiction = true
        }
        this.getCorporator()
      }
      if(this.jurisdictionB){
        this.jurisdiction = true
        this.getCorporator()
      }
    }
  },
  mounted() {
    this.qywxSetting()
    this.getTableData()
    let info = JSON.parse(localStorage.getItem('info'));
    this.companyName  = info.companyName
  },
  methods: {
    //请求列表数据
    getTableData() {
      console.log()
      console.log('key',this.departmentKey)
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name:this.departmentKey,
        userId:this.jurisdiction ? '' : this.$store.state.loginRoot.userInfo.userId
      };
      queryPageDepartment(data)
      .then((res) => {
        this.loading = false;
        this.tableData = res.data.pageInfo.list || [];
        this.total = res.data.pageInfo.total;
        console.log('this.tableData',this.tableData)
      })
      .catch((err) => {
        this.loading = false;
        this.tableData = [];
        console.log(err);
      });
    },
    qywxSetting(){
      qywxSetting({ignore:true})
      .then(res=>{
        console.log(res)
        if (res.success && res.code == 200 && res.data) {
          this.qywxSwitch = res.data.qywxSwitch
          this.isQywxAuth = res.data.isQywxAuth
        }
      })
      .catch(err=>{
        console.log(err)
      })
    },
    //查询成员列表 id:部门id
    async getCompanyList(id) {
      console.log(id)
      let data = {
        pageSize:999,
        departmentId:this.departmentId,
        name:this.memberKey
      }
      let result = await queryNotDepart(data)
      if(result.data){
        this.staffList = result.data
        this.staffList.forEach(v => {
          v.isSelect = false
        });
      }
      else{
        this.staffList = []
      }

    },

    //查询管理员列表
    async getAdministrator() {
      let data = {
        name:this.administratorKey,
        pageSize:999
      }
      let result = await queryPageManager(data)
      console.log(result)
      if(result.data && result.data.pageInfo.list){
        this.administratorList = result.data.pageInfo.list
      }else{
        this.administratorList = []
      }
    },


    //查看所有
    checkAll(lsitData, checkAuth){
      console.log('lsitData',lsitData)
      this.checkVisible = true
      this.memberData = lsitData
      this.checkAuth = checkAuth
      this.checkTitle = checkAuth ? '查看已授权成员' : '查看待授权成员'
    },
    //新增部门
    addDepartment(row) {
      this.$prompt("请输入部门名称", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then( async ({ value }) => {
          addDepartment({
            name:value
          })
          .then(res=>{
            this.$message({
              type: "success",
              message: "添加成功",
              center: true,
            });
            this.getTableData()
          })
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消",
            center: true,
          });
        });
    },

    //解散部门
    dissolve(item){
      this.$confirm("是否确认解散该分组，解散后不可恢复。", "提示", {
        onConfirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info ",
      })
      .then(() => {
        let data = {
          departmentId:item.departmentId
        }
        disbandDepartment(data)
        .then(res=>{
          this.getTableData()
        })
      })
      .catch((err) => {});
    },
    //编辑成员
    editDepartment(item){
      this.editVisible = true
      this.departmentId = item.departmentId
      let data = {
        departmentId:item.departmentId
      }
      queryDepartment(data)
      .then(res=>{
        this.departmentId = item.departmentId
        this.currentDepartment = res.data
        console.log(this.currentDepartment)
        this.checkedArr = this.currentDepartment.adminEmployees || []
        this.checkedMemberArr = this.currentDepartment.employeeList || []
        let arr = []
        if(res.data.adminEmployees){
          arr = res.data.adminEmployees.map(v=>{
            return v.empId
          })
        }

        this.checkedAdministrator = arr
        this.getCompanyList()
        this.getAdministrator()
      })
    },
    //全选
    selectedAll(){
      let than = this
      if(than.checkedMember.length !== this.checkedMemberArr.length){
        this.checkedMemberArr.forEach((item,index)=>{
          than.checkedMember.push(item.empId)
        })
      }else{
        than.checkedMember = []
      }

    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },

    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.getTableData();
    },
    //设置checkbox选择状态
    checkbox_tap(index,type){
      console.log('addMember',this.addMember)
      return console.log('Administrator',this.checkedMember)
    },
    //确认管理员
    administratoronConfirm(){
      let than = this
      this.administratorVisible = false
      let arr = []
      console.log(this.checkedAdministrator)
      than.administratorList.forEach(item=>{
        for(var i in than.checkedAdministrator){

          if(item.empId == than.checkedAdministrator[i]){

            arr.push(item)
            console.log(item.employeeName)
          }
        }
      })
      this.checkedArr = arr
    },
    memberConfirm(){
      let than = this
      this.memberVisible = false
      let arr = []
      console.log(this.checkedMemberArr,'addMember')
      console.log(this.checkedMemberArr,'checkedMemberArr')
      if(this.addMember.length == 0){
        return
      }
      than.staffList.forEach(item=>{
        for(var i in than.addMember){

          if(item.empId == than.addMember[i] ){

            item.isSelect = false
            arr.push(item)
          }
        }
      })
      arr = arr.concat(than.checkedMemberArr)
      console.log(arr ,'arr')
      for (var i = 0; i < arr.length - 1; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].empId == arr[j].empId) {
            arr.splice(j, 1);
          }
        }
      }
      this.checkedMemberArr = arr
    },
    // 移除成员
    remove_member(){

      let than = this
      for(var i in than.checkedMember){
        than.checkedMemberArr.forEach((v,k)=>{
          if(than.checkedMember[i]  == v.empId){

            than.checkedMemberArr.splice(k,1)
          }
        })
      }
      than.checkedMember = []
    },
    //保存部门
    saveDepartment(){
      let employees = [];
      if(this.checkedArr){

        this.checkedArr.forEach(v=>{
          if(v.routers){
            v.routers = JSON.parse(v.routers)
          }
          employees.push(v)
        })
      }else{
        employees = ''
      }

      if(this.checkedMemberArr){
        this.checkedMemberArr.forEach(v=>{
          if(v.routers){
            v.routers = JSON.parse(v.routers)
          }
          employees.push(v)
        })
      }else{
        employees = ''
      }
      let data = {
        name:this.currentDepartment.name,
        departmentId:this.departmentId,
        employees: employees
      }
      updateDepartment(data)
      .then(res=>{
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.initData()
        this.getTableData()
      })
    },
    initData(){
      this.checkedAdministrator = [] //选择的管理员
      this.editVisible = false
      this.checkedMember = [] // 选择成员
      this.addMember = [] //新增成员
      this.checkedArr = ''//当前选择的管理员
      this.checkedMemberArr = ''// 当前选择的成员列表
      this.adminEmployees = '' //已选择的管理员
      this.administratorList = [] //成员列表
    },
    toAdministratoronList(){
      this.$router.push('/AdministratorList')
    },
    importConfirm() {
      if(!this.isQywxAuth){
        this.$message({
          message: "请先授权企业微信",
          type: "error",
        });
        return;
      }
      this.enterpriseLicense = false
      this.loading = true
      syncDepartment({ignore:true})
      .then(res=>{
        this.loading = false
        console.log(res)
        if (res && res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getTableData()
        } else {
          this.$message({
            message: res.message || '操作失败，请稍后重试',
            type: "error",
          });
        }
      })
      .catch(err=>{
        this.loading = false
        console.log(err)
      })
    },
    toAppletConfig() {
      this.$router.push('/appletConfig?activeName=qywx')
    },
    toEnterpriseMember() {
      this.$router.push('/appletConfig?activeName=qywx')
    }
  },
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
.member-box{
  padding: 5px 10px;
  background: #2979ff;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  border-radius: 3px;
  margin: 5px;
}
.more-box{
  padding: 5px 10px;
  background: #FD634E;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 14px;
  color: #ffffff;
  border-radius: 3px;
  margin: 5px;
}
.member-scroll{
  max-height: 300px;
  overflow-y: auto;
}
</style>
